import checkLicenseModel from "../../models/webinars/checkLicenseModel";
import { log } from "../../helpers/index";

class CheckLicenseController {
  constructor() {
    this.name = "h";
    this.value = `; ${document.cookie}`;
    this.parts = this.value.split(`; ${this.name}=`);
    this.isCookieH =
      this.parts.length === 2 ? this.parts.pop().split(";").shift() : false;
  }

  checkLicense(updatePlayerState) {
    if (this.isCookieH) {
      checkLicenseModel()
        .then((res) => {
          if (res.status === 200) {
            const filter = res.data.filter((e) => {
              if (
                (e.software === "WEB" ||
                  e.software === "SCOUT_EXT_PRO" ||
                  e.software === "SCOUT_EXT_PRO_NEW") &&
                !e.trial
              ) {
                return e;
              }
            });
            updatePlayerState("isLicense", filter.length > 0);
          }
        })
        .catch((e) => {
          log(e);
        });
    } else {
      updatePlayerState("isLicense", false);
    }
  }
}

export default CheckLicenseController;
