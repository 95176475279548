import React from "react";
import "./index.scss";
import Container from "../../common/Container";
import FilterButton from "./FilterButton";
import PropTypes from "prop-types";

const FilterPanel = ({ info, filter, updatePlayerState }) => {
  return (
    <section className="FilterPanel">
      <Container webSize>
        <div className="FilterPanel__wrap">
          <p className="FilterPanel__title">{info.title}</p>
          <div className="FilterPanel__filters">
            {info.topics.length > 0 &&
              info.topics.map((text, i) => (
                <FilterButton
                  textButton={text}
                  filter={filter}
                  key={i}
                  updatePlayerState={updatePlayerState}
                />
              ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
FilterPanel.prototype = {
  updatePlayerState: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  filter: PropTypes.string,
};

export default FilterPanel;
