import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";

class AnalyticsController {
  constructor() {
    this.params = new URL(window.location.href).searchParams;
    this.software = this.params.get("software") || "LANDING";
    this.states = ["PageCTA", "Checkout", "Webinars"];
  }

  createCategory(state) {
    this.category = `${this.software}|${state}`;
  }

  createSendEvent(state, action, label, sendToGA) {
    this.createCategory(state);
    this.send(action, label, sendToGA);
  }

  send(action, label, sendToGa) {
    sendToGa &&
      trackGa(action, {
        category: this.category,
        label: label,
        transport_type: "beacon",
      });
    track(this.category, action, label, null, this.software);
  }

  sendClickCourseEvent(label) {
    return this.createSendEvent(this.states[2], "view", label, true);
  }
  sendClickCourseEventTegA(label, action) {
    return this.createSendEvent(this.states[2], action, label, true);
  }
}

export default AnalyticsController;
