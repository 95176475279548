import React, { useEffect, useMemo } from "react";
import Container from "../../common/Container";
import MasterItem from "./MasterclassesItem";
import "./index.scss";
import PropTypes from "prop-types";
import AnalyticsController from "../../../controllers/webinars/analyticsController";
import WaModeController from "../../../controllers/webinars/waModeController";
import CheckLicenseController from "../../../controllers/webinars/checkLicenseController";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const analyticsController =
  typeof window !== "undefined" && new AnalyticsController();
const waModeController =
  typeof window !== "undefined" && new WaModeController();
const checkLicenseController =
  typeof window !== "undefined" && new CheckLicenseController();

const titleOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => text,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content[0].marks[0]?.type === "bold" ? children : null,
  },
};

const Masterclasses = ({
  items,
  paidList,
  freeList,
  getPlayerState,
  updatePlayerState,
  sectionDescriptions,
  h1,
  onMount,
}) => {
  useEffect(() => {
    onMount && onMount();
  }, [getPlayerState.filter]);

  const handlerClick = (e, isPaid, videoUrl, title) => {
    const checkTegA = e.target.tagName === "A";
    analyticsController.sendClickCourseEvent(title);
    checkLicenseController.checkLicense(updatePlayerState);
    if (isPaid) {
      getPlayerState.isWaMode
        ? waModeController.blockPaidContent(getPlayerState.heightPage)
        : updatePlayerState("isOpenPricing", true);
    } else if (!checkTegA) {
      getPlayerState.isWaMode
        ? waModeController.unblockPaidContent(videoUrl)
        : updatePlayerState("urlYoutubeModal", videoUrl);
    }
  };

  const currentDescription =
    sectionDescriptions.length > 0 ? sectionDescriptions[0] : null;

  const getMasterClassesFromQuery = (isFree, filter) => {
    const list = isFree ? freeList : paidList;
    return (
      <div
        className={`Masterclasses-content__video Masterclasses-content__video_${
          isFree ? "free" : "paid"
        }`}
      >
        <p className="Masterclasses-content__title">
          {renderRichText(
            isFree
              ? currentDescription.node.freeDescription
              : currentDescription.node.paidDescription,
            titleOptions
          )}
        </p>
        <div className="Masterclasses-content__line" />
        <div className="Masterclasses-content__items">
          {list.length > 0 &&
            list.map((objId, index) => {
              const item = items.find(
                (e) =>
                  e.node.id === objId.id &&
                  (isFree ? !e.node.paid : e.node.paid)
              );
              return filter === "" || item.node.topics.includes(filter) ? (
                <MasterItem
                  data={item.node}
                  key={index}
                  isLicense={getPlayerState.isLicense}
                  handlerClick={handlerClick}
                  isWaMode={getPlayerState.isWaMode}
                />
              ) : null;
            })}
        </div>
      </div>
    );
  };
  const paidCourses = useMemo(
    () => getMasterClassesFromQuery(false, getPlayerState.filter),
    [
      getPlayerState.isLicense,
      getPlayerState.isWaMode,
      getPlayerState.filter,
      getPlayerState.heightPage,
    ]
  );
  const freeCourses = useMemo(
    () => getMasterClassesFromQuery(true, getPlayerState.filter),
    [
      getPlayerState.isLicense,
      getPlayerState.isWaMode,
      getPlayerState.filter,
      getPlayerState.heightPage,
    ]
  );

  return (
    <section className="Masterclasses">
      <Container webSize>
        <h1>{h1}</h1>
        <div className="Masterclasses-content">
          {freeCourses}
          {paidCourses}
        </div>
      </Container>
    </section>
  );
};
Masterclasses.prototype = {
  getPlayerState: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  paidList: PropTypes.array,
  freeList: PropTypes.array,
  items: PropTypes.object,
  sectionDescriptions: PropTypes.string,
  onMount: PropTypes.func,
};

export default Masterclasses;
