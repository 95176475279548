import React from "react";
import VideoItem from "../../../common/webinars/VideoItem";
import "./index.scss";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES } from "@contentful/rich-text-types";

const MasterItem = ({ data, isLicense, handlerClick, isWaMode }) => {
  const isPaid = isLicense ? false : data.paid;

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) =>
        isPaid ? (
          <span className="MasterItem__replacementText">
            Select a plan to get full access to the Extended course!
          </span>
        ) : (
          <a href={node.data.uri} target="_blank">
            {children}
          </a>
        ),
    },
  };

  const checkUrl = () => {
    const arr = data.videoUrl.split("?");
    const listParameters = [];

    if (arr.length > 1) {
      const parameters = arr[1].split("&");

      parameters.forEach((str) => {
        if (str.includes("listType=") || str.includes("list=")) {
          listParameters.push(str);
        }
      });
    }
    listParameters.push("modestbranding=1");

    const checkedParameters = listParameters.filter(Boolean).join("&");

    return `${arr[0]}?${checkedParameters}`;
  };

  return (
    <div
      className="MasterItem"
      onClick={(e) => handlerClick(e, isPaid, checkUrl(), data.title)}
    >
      <VideoItem
        adClassName="MasterItem-image"
        imageData={data.image}
        isWaMode={isWaMode}
        offLazyLoad
      />
      <div className="MasterItem-info">
        <div className="MasterItem-info__topics">
          {data.topics.map((item, i) => (
            <div key={i} className="MasterItem-info__topicsItem">
              {item}
            </div>
          ))}
        </div>
        <p className="MasterItem-info__title">{data.title}</p>
        <div className="MasterItem-info__description">
          {renderRichText(data.description, options)}
        </div>
        <button className="MasterItem-info__button">{data.button}</button>
      </div>
    </div>
  );
};

MasterItem.prototype = {
  data: PropTypes.object.isRequired,
  isLicense: PropTypes.bool.isRequired,
  handlerClick: PropTypes.func.isRequired,
  isWaMode: PropTypes.bool.isRequired,
};

export default MasterItem;
