import React, { useEffect, useMemo, useState } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/course-items.png";
import Navigation from "../components/common/webinars/Navigation";
import Masterclasses from "../components/masterclasses-templates/Masterclasses";
import { graphql } from "gatsby";
import FilterPanel from "../components/masterclasses-templates/FilterPanel";
import CheckLicenseController from "../controllers/webinars/checkLicenseController";

const checkLicenseController =
  typeof window !== "undefined" && new CheckLicenseController();

const MasterClassesPage = ({ data, pageContext }) => {
  const {
    title,
    metaTitle,
    metaKeywords,
    metaImage,
    h1,
    cta,
    paidItems,
    freeItems,
    metaDescription,
    slug,
    navigationItems,
  } = pageContext;

  const {
    contentfulMasterClassesFilterPanel,
    allContentfulMasterClassesItem,
    allContentfulSectionDescription,
  } = data;

  const [getPlayerState, setPlayerState] = useState({
    isShowYoutubeModal: false,
    urlYoutubeModal: "",
    isLicense: false,
    isOpenPricing: false,
    filter: "",
  });

  const updatePlayerState = (key, value) => {
    setPlayerState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    checkLicenseController.checkLicense(updatePlayerState);
  }, []);

  useEffect(() => {
    updatePlayerState("isShowYoutubeModal", !!getPlayerState.urlYoutubeModal);
  }, [getPlayerState.urlYoutubeModal]);

  const closeYoutubeModal = () => updatePlayerState("urlYoutubeModal", "");
  const closePricing = () => updatePlayerState("isOpenPricing", false);

  useEffect(() => {
    const handleKeyEsc = (e) => {
      if (e.keyCode === 27) {
        closeYoutubeModal();
      }
    };
    document.addEventListener("keydown", handleKeyEsc);

    return () => {
      document.removeEventListener("keydown", handleKeyEsc);
    };
  }, []);

  const navigationPanel = useMemo(
    () => (
      <Navigation
        mainTitle={cta}
        items={navigationItems}
        currentPage={slug}
        isBlueView
      />
    ),
    []
  );

  const filterPanel = useMemo(
    () => (
      <FilterPanel
        filter={getPlayerState.filter}
        updatePlayerState={updatePlayerState}
        info={contentfulMasterClassesFilterPanel}
      />
    ),
    [getPlayerState.filter]
  );

  return (
    <Layout
      isAbsoluteHeader
      urlYoutubeModal={getPlayerState.urlYoutubeModal}
      isShowYoutubeModal={getPlayerState.isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      isOpenPricing={getPlayerState.isOpenPricing}
      closePricing={closePricing}
    >
      <Seo
        title={title}
        metaTitle={metaTitle}
        keywords={metaKeywords}
        description={metaDescription}
        page={slug}
        manifest="browserconfig.xml"
        ogImg={!metaImage ? metaImage.file.url : OgImg}
      />
      {navigationPanel}
      {filterPanel}
      <Masterclasses
        sectionDescriptions={allContentfulSectionDescription.edges}
        h1={h1}
        items={allContentfulMasterClassesItem.edges}
        getPlayerState={getPlayerState}
        updatePlayerState={updatePlayerState}
        paidList={paidItems}
        freeList={freeItems}
      />
    </Layout>
  );
};
export const pageQuery = graphql`
  query masterClassesPageQuery($sectionDescriptionId: String) {
    allContentfulMasterClassesItem {
      edges {
        node {
          id
          title
          topics
          paid
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          videoUrl
          description {
            raw
          }
          button
        }
      }
    }
    allContentfulSectionDescription(
      filter: { id: { eq: $sectionDescriptionId } }
    ) {
      edges {
        node {
          id
          freeDescription {
            raw
          }
          paidDescription {
            raw
          }
        }
      }
    }
    contentfulMasterClassesFilterPanel {
      title
      topics
    }
  }
`;

export default MasterClassesPage;
