import React from "react";
import PropTypes from "prop-types";

const FilterButton = ({ textButton, filter, updatePlayerState }) => {
  const isActive = textButton === filter;

  const classes = [
    "FilterButton",
    textButton === filter && "FilterButton__active",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      className={classes}
      onClick={() => updatePlayerState("filter", isActive ? "" : textButton)}
    >
      {textButton}
    </button>
  );
};

FilterButton.prototype = {
  updatePlayerState: PropTypes.func.isRequired,
  textButton: PropTypes.string.isRequired,
  filter: PropTypes.string,
};

export default FilterButton;
