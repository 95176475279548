import axios from "axios";

const checkLicenseModel = () => {
  return axios.get(
    `${process.env.AUTH_HOST}/v1/licences/software?state=ACTIVE`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
};
export default checkLicenseModel;
